<template>
    <div class="contact-details-main">
        <div v-show="loading" id="semiTransparenDiv"></div>
        <div class="container-fluid">
            <div class="contact-details">
                <div class="container-fluid">
                    <div class="contact-details-heading">
                        <p>Schedule</p>
                    </div>
                </div>
                <div class="border-bottom">
                </div>
                <div class="container-fluid">
                    <div class="general-settings-main">
                        <div class="general-settings-heading">
                            <h4>General Settings</h4>
                        </div>
                        <div class="border-bottom">
                        </div>
                        <div class="setting-content">
                           
                            <div class="contents">
                                <h6>Delivery Window</h6>
                                <div class="maxmin-del-time">
                                    <div class="form-group">
                                        <label for="exampleInputslot1">Minimum Del. Time</label>
                                        <input type="number" class="form-control" id="exampleInputEmailslot1" aria-describedby="texth1" placeholder=".." v-model="min_delivery_time" min='0' >
                                    </div>
                                    <p>to</p>
                                    <div class="form-group">
                                        <label for="exampleInputslot1">Maximum Del. Time</label>
                                        <input type="number" class="form-control" id="exampleInputEmailslot1" aria-describedby="texth1" placeholder=".." v-model="max_delivery_time" min='0'  >
                                    </div>
                                </div>
                            </div>
                            <div class="contents">
                                <h6>Pickup Window</h6>
                                <div class="pick-window">
                                    <div class="form-group">
                                        <label for="exampleInputslot2">In minutes</label>
                                        <input type="number" class="form-control" id="exampleInputEmailslot2" aria-describedby="texth2" placeholder=".." v-model="pickup_window" min='0'  >
                                    </div>
                                    <div>
                                        <button @click="save_general_settings()" class="apply-button">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="customer-preview-main">
                        <!-- timeblocks -->
                        <div class="left-customer-preview ">
                            <!-- saved timeblocks -->
                            <div  v-for="(timeblock_result,index) in timeblock_results" :key="index"  class="timeblock-weekdays">
                                <div class="general-settings-main">
                                    <div class="general-settings-heading">
                                        <h4>Timeblock</h4>
                                    </div>
                                    <div class="border-bottom">
                                    </div>
                                    <div class="setting-content-timeblock">
                                        <div class="sales-channels-schedule">
                                            <div class="sales-channel-inner2-schedule">
                                                <div>
                                                    <label>Used for:</label>
                                                </div>
                                                <div class="switch-2">
                                                    Dine-in
                                                    <label class="switch-inner1">
                                                        <input type="checkbox" id="togBtn66" v-model="timeblock_result.dine_in">
                                                        <div :class="['slider ', timeblock_result.dine_in == true ? 'round' : 'round disabled']">
                                                        </div>
                                                    </label>
                                                </div>
                                                <div class="switch-2">
                                                    Take-Away
                                                    <label class="switch-inner1">
                                                        <input type="checkbox" id="togBtn67" v-model="timeblock_result.take_away">
                                                        <div :class="['slider ', timeblock_result.take_away == true ? 'round' : 'round disabled']">
                                                        </div>
                                                    </label>
                                                </div>
                                                <div class="switch-2">
                                                    Delivery
                                                    <label class="switch-inner1">
                                                        <input type="checkbox" id="togBtn68" v-model="timeblock_result.delivery">
                                                        <div :class="['slider ', timeblock_result.delivery == true ? 'round' : 'round disabled']"> 
                                                        </div>
                                                    </label>
                                                </div>
                                                <div  class="bin-icon">
                                                    <i @click="delete_added_timeblock(timeblock_result.id,index)" class="fa fa-trash-o" aria-hidden="true"> </i>
                                                    <button  class="sve-btn" @click="update_timeblock(timeblock_result,index)" >SAVE</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="time-slot">
                                            <div class="contents">
                                                <div class="maxmin-del-time">
                                                    <div class="form-group">
                                                        <label for="exampleInputslot1">Starts At</label>
                                                        <input type="time" class="form-control" id="exampleInputEmailslot1" aria-describedby="texth1" placeholder=".." v-model="timeblock_result.start">
                                                    </div>
                                                    <p>to</p>
                                                    <div class="form-group">
                                                    <label for="exampleInputslot1">Ends At</label>
                                                    <input type="time" class="form-control" id="exampleInputEmailslot1" aria-describedby="texth1" placeholder=".." v-model="timeblock_result.end">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="exampleFormControlSelect1">Time Slots Interval</label>
                                                <v-select  :options="interval_options" v-model="timeblock_result.brand_time_slot"   ></v-select>
                                            </div>
                                        </div>
                                        <div class="order-capacity">
                                            <div class="form-group">
                                                <label for="exampleInputslot12">Order Capacity Per Slot</label>
                                                <input type="number" class="form-control" id="exampleInputEmailslot12" aria-describedby="texth1" placeholder=".." v-model="timeblock_result.capacity"  min='0'>
                                            </div>
                                        </div>

                                        <div class="check-limit-schedule">
                                            <label class="container">
                                            <input type="checkbox" v-model="timeblock_result.monday">
                                            <span class="checkmark"></span>
                                            Mon
                                            </label>
                                            <label class="container">
                                            <input type="checkbox" v-model="timeblock_result.tuesday">
                                            <span class="checkmark"></span>
                                            Tues
                                            </label>
                                            <label class="container">
                                            <input type="checkbox" v-model="timeblock_result.wednesday">
                                            <span class="checkmark"></span>
                                            Wed
                                            </label>
                                            <label class="container">
                                            <input type="checkbox" v-model="timeblock_result.thursday">
                                            <span class="checkmark"></span>
                                            Thu
                                            </label>
                                            <label class="container">
                                            <input type="checkbox" v-model="timeblock_result.friday">
                                            <span class="checkmark"></span>
                                            Fri
                                            </label>
                                            <label class="container">
                                            <input type="checkbox" v-model="timeblock_result.saturday">
                                            <span class="checkmark"></span>
                                            Sat
                                            </label>
                                            <label class="container">
                                            <input type="checkbox" v-model="timeblock_result.sunday">
                                            <span class="checkmark"></span>
                                            Sun
                                            </label>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>

                            <!-- custom timeblocks -->
                            <div  v-for="(add_timeblock,index) in add_timeblocks" :key="index"  class="timeblock-weekdays">
                                <div class="general-settings-main">
                                    <div class="general-settings-heading">
                                        <h4>Timeblock</h4>
                                    </div>
                                    <div class="border-bottom">
                                    </div>
                                    <div class="setting-content-timeblock">
                                        <div class="sales-channels-schedule">
                                            <div class="sales-channel-inner2-schedule">
                                                <div>
                                                    <label>Used for:</label>
                                                </div>
                                                <div class="switch-2">
                                                    Dine-in
                                                    <label class="switch-inner1">
                                                        <input type="checkbox" id="togBtn66" v-model="add_timeblock.dine_in">
                                                        <div :class="['slider ', add_timeblock.dine_in == true ? 'round' : 'round disabled']">
                                                        </div>
                                                    </label>
                                                </div>
                                                <div class="switch-2">
                                                    Take-Away
                                                    <label class="switch-inner1">
                                                        <input type="checkbox" id="togBtn67" v-model="add_timeblock.take_away">
                                                        <div :class="['slider ', add_timeblock.take_away == true ? 'round' : 'round disabled']">
                                                        </div>
                                                    </label>
                                                </div>
                                                <div class="switch-2">
                                                    Delivery
                                                    <label class="switch-inner1">
                                                        <input type="checkbox" id="togBtn68" v-model="add_timeblock.delivery">
                                                        <div :class="['slider ', add_timeblock.delivery == true ? 'round' : 'round disabled']"> 
                                                        </div>
                                                    </label>
                                                </div>
                                                <div  class="bin-icon">
                                                    <i @click="delete_custom_timeblock(index)" class="fa fa-trash-o" aria-hidden="true"> </i>
                                                    <button  class="sve-btn" @click="create_timeblock(add_timeblock,index)" >SAVE</button>
                                                </div>
                                            </div>
                                        </div>

                                    


                                        <div class="time-slot">
                                            <div class="contents">
                                                <div class="maxmin-del-time">
                                                    <div class="form-group">
                                                        <label for="exampleInputslot1">Starts At</label>
                                                        <input type="time" class="form-control" id="exampleInputEmailslot1" aria-describedby="texth1" placeholder=".." v-model="add_timeblock.start">
                                                    </div>
                                                    <p>to</p>
                                                    <div class="form-group">
                                                        <label for="exampleInputslot1">Ends At</label>
                                                        <input type="time" class="form-control" id="exampleInputEmailslot1" aria-describedby="texth1" placeholder=".." v-model="add_timeblock.end">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="exampleFormControlSelect1">Time Slots Interval</label>
                                                <v-select  :options="interval_options" v-model="add_timeblock.brand_time_slot"   ></v-select>
                                            </div>
                                        </div>
                                        <div class="order-capacity">
                                            <div class="form-group">
                                                <label for="exampleInputslot12">Order Capacity Per Slot</label>
                                                <input type="number" class="form-control" id="exampleInputEmailslot12" aria-describedby="texth1" placeholder=".." v-model="add_timeblock.capacity" min='0' >
                                            </div>
                                        </div>
                                        <div class="check-limit-schedule">
                                            <label class="container">
                                            <input type="checkbox" v-model="add_timeblock.monday">
                                            <span class="checkmark"></span>
                                            Mon
                                            </label>
                                            <label class="container">
                                            <input type="checkbox" v-model="add_timeblock.tuesday">
                                            <span class="checkmark"></span>
                                            Tues
                                            </label>
                                            <label class="container">
                                            <input type="checkbox" v-model="add_timeblock.wednesday">
                                            <span class="checkmark"></span>
                                            Wed
                                            </label>
                                            <label class="container">
                                            <input type="checkbox" v-model="add_timeblock.thursday">
                                            <span class="checkmark"></span>
                                            Thu
                                            </label>
                                            <label class="container">
                                            <input type="checkbox" v-model="add_timeblock.friday">
                                            <span class="checkmark"></span>
                                            Fri
                                            </label>
                                            <label class="container">
                                            <input type="checkbox" v-model="add_timeblock.saturday">
                                            <span class="checkmark"></span>
                                            Sat
                                            </label>
                                            <label class="container">
                                            <input type="checkbox" v-model="add_timeblock.sunday">
                                            <span class="checkmark"></span>
                                            Sun
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="add-menu-category-timeblock">
                                <a href="javascript:void(0)" @click="clone_timeblock()">+ ADD ANOTHER TIMEBLOCK</a>
                            </div>

                        </div>
                        <!-- customer preview -->
                        <div class="customer-preview-inner-right">
                            <h4>Customer Preview</h4>
                            <div class="customer-preview-inner">
                                <div class="tab-container">
                                    <div class="tab-menu">
                                        <ul>
                                            <li><a  class="tab-a active-a" data-id="tab1">Delivery</a></li>
                                            <li><a  class="tab-a" data-id="tab2">Pickup</a></li>
                                            <li><a  class="tab-a" data-id="tab3">Dining</a></li>
                                        </ul>
                                    </div>
                                    <div class="tab tab-active" data-id="tab1">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th class="back-change faded-time" scope="row">Monday</th>
                                                    <td class="faded-time">12:30 PM - 1:30 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Tuesday</th>
                                                    <td>1:00 PM - 2:00 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Wednesday</th>
                                                    <td class="back-change">1:30 PM - 2:30 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Thursday</th>
                                                    <td>2:00 PM - 3:00 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Friday</th>
                                                    <td>2:30 PM - 3:30 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Saturday</th>
                                                    <td>3:00 PM - 4:00 PM </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Sunday</th>
                                                    <td>4:30 PM - 5:30 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td>5:00 PM - 6:00 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td>5:30 PM - 6:30 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td>6:00 PM - 7:00 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td>6:30 PM - 7:30 PM</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="tab" data-id="tab2">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Monday</th>
                                                    <td>12:30 PM - 1:30 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Tuesday</th>
                                                    <td>1:00 PM - 2:00 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Wednesday</th>
                                                    <td>1:30 PM - 2:30 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Thursday</th>
                                                    <td>2:00 PM - 3:00 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Friday</th>
                                                    <td>2:30 PM - 3:30 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Saturday</th>
                                                    <td>3:00 PM - 4:00 PM </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Sunday</th>
                                                    <td>4:30 PM - 5:30 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td>5:00 PM - 6:00 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td>5:30 PM - 6:30 PM</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="tab" data-id="tab3">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Monday</th>
                                                    <td>12:30 PM - 1:30 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Tuesday</th>
                                                    <td>1:00 PM - 2:00 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Wednesday</th>
                                                    <td>1:30 PM - 2:30 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Thursday</th>
                                                    <td>2:00 PM - 3:00 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Friday</th>
                                                    <td>2:30 PM - 3:30 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Saturday</th>
                                                    <td>3:00 PM - 4:00 PM </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Sunday</th>
                                                    <td>4:30 PM - 5:30 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td>5:00 PM - 6:00 PM</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td>5:30 PM - 6:30 PM</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- saved timeblocks -->
                    <!-- <div  v-for="(timeblock_result,index) in timeblock_results" :key="index"  class="timeblock-weekdays">
                        <div class="general-settings-main">
                        <div class="general-settings-heading">
                            <h4>Timeblock</h4>
                        </div>
                        <div class="border-bottom">
                        </div>
                        <div class="setting-content-timeblock">
                            <div class="sales-channels-schedule">
                                <div class="sales-channel-inner2-schedule">
                                    <div>
                                        <label>Used for:</label>
                                    </div>
                                    <div class="switch-2">
                                        Dine-in
                                        <label class="switch-inner1">
                                            <input type="checkbox" id="togBtn66" v-model="timeblock_result.dine_in">
                                            <div :class="['slider ', timeblock_result.dine_in == true ? 'round' : 'round disabled']">
                                            </div>
                                        </label>
                                    </div>
                                    <div class="switch-2">
                                        Take-Away
                                        <label class="switch-inner1">
                                            <input type="checkbox" id="togBtn67" v-model="timeblock_result.take_away">
                                            <div :class="['slider ', timeblock_result.take_away == true ? 'round' : 'round disabled']">
                                            </div>
                                        </label>
                                    </div>
                                    <div class="switch-2">
                                        Delivery
                                        <label class="switch-inner1">
                                            <input type="checkbox" id="togBtn68" v-model="timeblock_result.delivery">
                                            <div :class="['slider ', timeblock_result.delivery == true ? 'round' : 'round disabled']"> 
                                            </div>
                                        </label>
                                    </div>
                                    <div  class="bin-icon">
                                        <i @click="delete_added_timeblock(timeblock_result.id,index)" class="fa fa-trash-o" aria-hidden="true"> </i>
                                        <button  class="sve-btn" @click="update_timeblock(timeblock_result,index)" >SAVE</button>
                                    </div>
                                </div>
                            </div>

                          



                            <div class="time-slot">
                                <div class="contents">
                                    <div class="maxmin-del-time">
                                        <div class="form-group">
                                            <label for="exampleInputslot1">Starts At</label>
                                            <input type="time" class="form-control" id="exampleInputEmailslot1" aria-describedby="texth1" placeholder=".." v-model="timeblock_result.start">
                                        </div>
                                        <p>to</p>
                                        <div class="form-group">
                                            <label for="exampleInputslot1">Ends At</label>
                                            <input type="time" class="form-control" id="exampleInputEmailslot1" aria-describedby="texth1" placeholder=".." v-model="timeblock_result.end">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Time Slots Interval</label>
                                    <v-select  :options="interval_options" v-model="timeblock_result.brand_time_slot"   ></v-select>
                                </div>
                            </div>
                            <div class="order-capacity">
                                <div class="form-group">
                                    <label for="exampleInputslot12">Order Capacity Per Slot</label>
                                    <input type="number" class="form-control" id="exampleInputEmailslot12" aria-describedby="texth1" placeholder=".." v-model="timeblock_result.capacity"  min='0'>
                                </div>
                            </div>
                            <div class="check-limit-schedule">
                                <label class="container">
                                <input type="checkbox" v-model="timeblock_result.monday">
                                <span class="checkmark"></span>
                                Mon
                                </label>
                                <label class="container">
                                <input type="checkbox" v-model="timeblock_result.tuesday">
                                <span class="checkmark"></span>
                                Tues
                                </label>
                                <label class="container">
                                <input type="checkbox" v-model="timeblock_result.wednesday">
                                <span class="checkmark"></span>
                                Wed
                                </label>
                                <label class="container">
                                <input type="checkbox" v-model="timeblock_result.thursday">
                                <span class="checkmark"></span>
                                Thu
                                </label>
                                <label class="container">
                                <input type="checkbox" v-model="timeblock_result.friday">
                                <span class="checkmark"></span>
                                Fri
                                </label>
                                <label class="container">
                                <input type="checkbox" v-model="timeblock_result.saturday">
                                <span class="checkmark"></span>
                                Sat
                                </label>
                                <label class="container">
                                <input type="checkbox" v-model="timeblock_result.sunday">
                                <span class="checkmark"></span>
                                Sun
                                </label>
                            </div>
                        </div>
                        </div>
                    </div> -->

                    <!-- custom timeblocks -->
                    <!-- <div  v-for="(add_timeblock,index) in add_timeblocks" :key="index"  class="timeblock-weekdays">
                        <div class="general-settings-main">
                            <div class="general-settings-heading">
                                <h4>Timeblock</h4>
                            </div>
                            <div class="border-bottom">
                            </div>
                            <div class="setting-content-timeblock">
                                <div class="sales-channels-schedule">
                                    <div class="sales-channel-inner2-schedule">
                                        <div>
                                            <label>Used for:</label>
                                        </div>
                                        <div class="switch-2">
                                            Dine-in
                                            <label class="switch-inner1">
                                                <input type="checkbox" id="togBtn66" v-model="add_timeblock.dine_in">
                                                <div :class="['slider ', add_timeblock.dine_in == true ? 'round' : 'round disabled']">
                                                </div>
                                            </label>
                                        </div>
                                        <div class="switch-2">
                                            Take-Away
                                            <label class="switch-inner1">
                                                <input type="checkbox" id="togBtn67" v-model="add_timeblock.take_away">
                                                <div :class="['slider ', add_timeblock.take_away == true ? 'round' : 'round disabled']">
                                                </div>
                                            </label>
                                        </div>
                                        <div class="switch-2">
                                            Delivery
                                            <label class="switch-inner1">
                                                <input type="checkbox" id="togBtn68" v-model="add_timeblock.delivery">
                                                <div :class="['slider ', add_timeblock.delivery == true ? 'round' : 'round disabled']"> 
                                                </div>
                                            </label>
                                        </div>
                                        <div  class="bin-icon">
                                            <i @click="delete_custom_timeblock(index)" class="fa fa-trash-o" aria-hidden="true"> </i>
                                            <button  class="sve-btn" @click="create_timeblock(add_timeblock,index)" >SAVE</button>
                                        </div>
                                    </div>
                                </div>

                               


                                <div class="time-slot">
                                    <div class="contents">
                                        <div class="maxmin-del-time">
                                            <div class="form-group">
                                                <label for="exampleInputslot1">Starts At</label>
                                                <input type="time" class="form-control" id="exampleInputEmailslot1" aria-describedby="texth1" placeholder=".." v-model="add_timeblock.start">
                                            </div>
                                            <p>to</p>
                                            <div class="form-group">
                                                <label for="exampleInputslot1">Ends At</label>
                                                <input type="time" class="form-control" id="exampleInputEmailslot1" aria-describedby="texth1" placeholder=".." v-model="add_timeblock.end">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Time Slots Interval</label>
                                        <v-select  :options="interval_options" v-model="add_timeblock.brand_time_slot"   ></v-select>
                                    </div>
                                </div>
                                <div class="order-capacity">
                                    <div class="form-group">
                                        <label for="exampleInputslot12">Order Capacity Per Slot</label>
                                        <input type="number" class="form-control" id="exampleInputEmailslot12" aria-describedby="texth1" placeholder=".." v-model="add_timeblock.capacity" min='0' >
                                    </div>
                                </div>
                                <div class="check-limit-schedule">
                                    <label class="container">
                                    <input type="checkbox" v-model="add_timeblock.monday">
                                    <span class="checkmark"></span>
                                    Mon
                                    </label>
                                    <label class="container">
                                    <input type="checkbox" v-model="add_timeblock.tuesday">
                                    <span class="checkmark"></span>
                                    Tues
                                    </label>
                                    <label class="container">
                                    <input type="checkbox" v-model="add_timeblock.wednesday">
                                    <span class="checkmark"></span>
                                    Wed
                                    </label>
                                    <label class="container">
                                    <input type="checkbox" v-model="add_timeblock.thursday">
                                    <span class="checkmark"></span>
                                    Thu
                                    </label>
                                    <label class="container">
                                    <input type="checkbox" v-model="add_timeblock.friday">
                                    <span class="checkmark"></span>
                                    Fri
                                    </label>
                                    <label class="container">
                                    <input type="checkbox" v-model="add_timeblock.saturday">
                                    <span class="checkmark"></span>
                                    Sat
                                    </label>
                                    <label class="container">
                                    <input type="checkbox" v-model="add_timeblock.sunday">
                                    <span class="checkmark"></span>
                                    Sun
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div> -->

             
                    <!-- <div class="add-menu-category-timeblock">
                        <a href="javascript:void(0)" @click="clone_timeblock()">+ ADD ANOTHER TIMEBLOCK</a>
                    </div> -->
                    <!-- <button class="schedule-button">SAVE</button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const axios = require('axios').default;
    const baseUrl =  process.env.VUE_APP_BASE_URL || 'http://192.168.0.159:8080'

    export default {
        name: 'BrandsSchedule',
        data () {
            return {
                // general vars
                loading:false,
                showHeader: true,
                errors:[],

                min_delivery_time:'',
                max_delivery_time:'',
                pickup_window:'',

                timeblock_results:[],
                add_timeblocks:[],

                interval_options:[
                    '15 mins',
                    '30 mins',
                    '45 mins',
                    '1 hr',
                    '1 hr 30 mins',
                    '2 hrs',
                    'other',

                ],


           
            }
            },
        methods: {
            async save_general_settings(){
                this.errors=[]
                if (this.validate_general_settings()) {
                    this.loading= true;
                    try{
                        const data =  { 
                            "min_delivery_time": this.min_delivery_time,
                            "max_delivery_time": this.max_delivery_time, 
                            "pickup_window": this.pickup_window, 
                        };
                        const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        var brand_id = this.$store.state.brand
                        var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                        const response =  await axios.patch(api_url,data,{headers})
                        this.loading= false;
                        await this.$toasted.success("General Settings Updated Successfully");
                        this.min_delivery_time= response.data.min_delivery_time;
                        this.max_delivery_time= response.data.max_delivery_time;
                        this.pickup_window=response.data.pickup_window;
                    }
                    catch (e) {
                        this.loading = false;
                        console.log('error is',e)
                        var i;
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                            this.$toasted.error(e.response.data.errors[i].message)
                        }
                        if(e.response.status== 450){ // logout the user
                            await this.$store.dispatch("logout");
                            await this.$router.push('/')
                        }else if (e.response.status== 401){
                            await this.$RefreshToken()
                        } 
                    }


                }

            },

            validate_general_settings(){
                if ((!this.min_delivery_time) || (this.min_delivery_time.length<=0)) {
                    this.errors.push("Please provide min delivery time!")
                    this.$toasted.error('Please provide min delivery time!');
                }

                if ((!this.max_delivery_time) || (this.max_delivery_time.length<=0) ) {
                    this.errors.push("Please provide max delivery time!")
                    this.$toasted.error('Please provide max delivery time!');
                }

                if ((!this.pickup_window) || (this.pickup_window.length<=0) ) {
                    this.errors.push("Please provide pickup window!")
                    this.$toasted.error('Please provide pickup window!');
                }

                if (!this.errors.length) {
                    return true;
                }
                 

            },

            // create new timeblock 
            clone_timeblock(){
                this.add_timeblocks.push({
                    dine_in:false,
                    take_away:false,
                    delivery:false,
                    start:'',
                    end:'',
                    brand_time_slot:'',
                    capacity:'',
                    monday:false,
                    tuesday:false,
                    wednesday:false,
                    thursday:false,
                    friday:false,
                    saturday:false,
                    sunday:false,
                })
            },

            delete_custom_timeblock(index){
                this.add_timeblocks.splice(index,1)
            },

            async create_timeblock(timeblock_data,index){
                try{
                    this.errors=[]
                    // setting order type
                    timeblock_data.order_type=[]
                    if (timeblock_data.dine_in==true){
                        timeblock_data.order_type.push('dine_in')
                    }
                    if (timeblock_data.take_away==true){
                        timeblock_data.order_type.push('take_away')
                    } if (timeblock_data.delivery==true){
                        timeblock_data.order_type.push('delivery')
                    }

                    // setting  days of week
                    timeblock_data.days_of_week=[]
                    if (timeblock_data.monday==true){
                        timeblock_data.days_of_week.push(0)
                    }
                    if (timeblock_data.tuesday==true){
                        timeblock_data.days_of_week.push(1)
                    }     
                    if (timeblock_data.wednesday==true){
                        timeblock_data.days_of_week.push(2)
                    }     
                    if (timeblock_data.thursday==true){
                        timeblock_data.days_of_week.push(3)
                    }     
                    if (timeblock_data.friday==true){
                        timeblock_data.days_of_week.push(4)
                    }     
                    if (timeblock_data.saturday==true){
                        timeblock_data.days_of_week.push(5)
                    }     
                    if (timeblock_data.sunday==true){
                        timeblock_data.days_of_week.push(6)
                    }

                    if (this.validate_timeblock(timeblock_data)) {
                        this.loading= true;
                        const data =  { 
                            "start": timeblock_data.start, 
                            "end":  timeblock_data.end, 
                            "order_type": timeblock_data.order_type, 
                            "capacity":  timeblock_data.capacity, 
                            "days_of_week":timeblock_data.days_of_week
                        };
                        const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        var brand_id = this.$store.state.brand
                        var api_url = `${baseUrl}/api/brands/`+brand_id+`/time-blocks/`
                        const response =  await axios.post(api_url,data,{headers})
                        this.loading= false;
                        await this.$toasted.success("Timeblock Created Successfully");
                        var result=response.data
                        // dine in
                        if(result.order_type.includes("dine_in")){
                            result.dine_in=true
                        }else{
                            result.dine_in=false
                        }
                        // take_away
                        if(result.order_type.includes("take_away")){
                            result.take_away=true
                        }else{
                            result.take_away=false
                        } 
                        // delivery
                        if(result.order_type.includes("delivery")){
                            result.delivery=true
                        }else{
                            result.delivery=false
                        }

                        // monday
                        if(result.days_of_week.includes(1)){
                            result.monday=true
                        }else{
                            result.monday=false
                        }

                        if(result.days_of_week.includes(2)){
                            result.tuesday=true
                        }else{
                            result.tuesday=false
                        }    
                        if(result.days_of_week.includes(3)){
                            result.wednesday=true
                        }else{
                            result.wednesday=false
                        }    
                        if(result.days_of_week.includes(4)){
                            result.thursday=true
                        }else{
                            result.thursday=false
                        }    
                        if(result.days_of_week.includes(5)){
                            result.friday=true
                        }else{
                            result.friday=false
                        }    
                        if(result.days_of_week.includes(6)){
                            result.saturday=true
                        }else{
                            result.saturday=false
                        }    
                        if(result.days_of_week.includes(7)){
                            result.sunday=true
                        }else{
                            result.sunday=false
                        }

                        this.timeblock_results.push(result)
                        this.add_timeblocks.splice(index,1)
                    }
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }

            },

            validate_timeblock(timeblock_data){
                if( (!timeblock_data.dine_in) && (!timeblock_data.take_away) && (!timeblock_data.delivery) ) {
                    this.errors.push("Please provide order type!")
                    this.$toasted.error('Please provide order type!');    
                }
                if(!timeblock_data.start){
                    this.errors.push("Please provide Starts At!")
                    this.$toasted.error('Please provide Starts At!');    
                }    
                if(!timeblock_data.end){
                    this.errors.push("Please provide Ends At!")
                    this.$toasted.error('Please provide Ends At!');   
                } 
                // if(!timeblock_data.brand_time_slot){
                //     this.errors.push("Please provide Time Slots Interval!")
                //     this.$toasted.error('Please provide Time Slots Interval!');   
                // }   
                if(!timeblock_data.capacity){
                    this.errors.push("Please provide Order Capacity!")
                    this.$toasted.error('Please provide Order Capacity!');   
                } 
                if( (!timeblock_data.monday) && (!timeblock_data.tuesday) && (!timeblock_data.wednesday)&& (!timeblock_data.thursday)&& (!timeblock_data.friday)&& (!timeblock_data.saturday)&& (!timeblock_data.sunday) ) {
                    this.errors.push("Please select atleast one day!")
                    this.$toasted.error('Please select atleast one day!');    
                }

                if(!this.errors.length) {
                    return true;
                }
            },

            // modify existing timeblock
            async delete_added_timeblock(timeblock_id,index){
                this.loading= true;
                try{
                    const headers = { 
                        // "Content-Type": "application/json",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var brand_id = this.$store.state.brand
                    var api_url = `${baseUrl}/api/brands/`+brand_id+`/time-blocks/`+timeblock_id+`/`
                    const response =  await axios.delete(api_url,{headers})
                    console.log('response',response)
                    this.loading= false;
                    await this.$toasted.success("Timeblock Deleted Successfully");
                    this.timeblock_results.splice(index,1)
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }

            },


            async update_timeblock(timeblock_data,index){                
                try{
                    this.errors=[]
                    // setting order type
                    timeblock_data.order_type=[]
                    if (timeblock_data.dine_in==true){
                        timeblock_data.order_type.push('dine_in')
                    }
                    if (timeblock_data.take_away==true){
                        timeblock_data.order_type.push('take_away')
                    } if (timeblock_data.delivery==true){
                        timeblock_data.order_type.push('delivery')
                    }

                    // setting  days of week
                    timeblock_data.days_of_week=[]
                    if (timeblock_data.monday==true){
                        timeblock_data.days_of_week.push(0)
                    }
                    if (timeblock_data.tuesday==true){
                        timeblock_data.days_of_week.push(1)
                    }     
                    if (timeblock_data.wednesday==true){
                        timeblock_data.days_of_week.push(2)
                    }     
                    if (timeblock_data.thursday==true){
                        timeblock_data.days_of_week.push(3)
                    }     
                    if (timeblock_data.friday==true){
                        timeblock_data.days_of_week.push(4)
                    }     
                    if (timeblock_data.saturday==true){
                        timeblock_data.days_of_week.push(5)
                    }     
                    if (timeblock_data.sunday==true){
                        timeblock_data.days_of_week.push(6)
                    }


                    if (this.validate_timeblock(timeblock_data)) {
                        this.loading= true;
                        const data =  { 
                            "start": timeblock_data.start, 
                            "end":  timeblock_data.end, 
                            "order_type": timeblock_data.order_type, 
                            "capacity":  timeblock_data.capacity, 
                            "days_of_week":timeblock_data.days_of_week
                        };
                        const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        var brand_id = this.$store.state.brand
                        var api_url = `${baseUrl}/api/brands/`+brand_id+`/time-blocks/`+timeblock_data.id+`/`
                        const response =  await axios.put(api_url,data,{headers})
                        this.loading= false;
                        await this.$toasted.success("Timeblock Updated Successfully");
                        var result=response.data
                        // dine in
                        if(result.order_type.includes("dine_in")){
                            result.dine_in=true
                        }else{
                            result.dine_in=false
                        }
                        // take_away
                        if(result.order_type.includes("take_away")){
                            result.take_away=true
                        }else{
                            result.take_away=false
                        } 
                        // delivery
                        if(result.order_type.includes("delivery")){
                            result.delivery=true
                        }else{
                            result.delivery=false
                        }   

                        
                        // monday
                        if(result.days_of_week.includes(1)){
                            result.monday=true
                        }else{
                            result.monday=false
                        }

                        if(result.days_of_week.includes(2)){
                            result.tuesday=true
                        }else{
                            result.tuesday=false
                        }    
                        if(result.days_of_week.includes(3)){
                            result.wednesday=true
                        }else{
                            result.wednesday=false
                        }    
                        if(result.days_of_week.includes(4)){
                            result.thursday=true
                        }else{
                            result.thursday=false
                        }    
                        if(result.days_of_week.includes(5)){
                            result.friday=true
                        }else{
                            result.friday=false
                        }    
                        if(result.days_of_week.includes(6)){
                            result.saturday=true
                        }else{
                            result.saturday=false
                        }    
                        if(result.days_of_week.includes(7)){
                            result.sunday=true
                        }else{
                            result.sunday=false
                        }

                        this.timeblock_results[index]=result
                    }
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }
            },

        },
        mounted(){
         

        },
        async created(){
            try{
                var brand_id = this.$store.state.brand
                this.loading= true;
                const headers = { 
                    "Content-Type": "application/json",
                    "Authorization":'Bearer '+this.$store.state.access_token
                };
                var api_url = `${baseUrl}/api/brands/`+brand_id+`/`
                const response =  await axios.get(api_url,{headers})
                this.loading= false;
                this.min_delivery_time= response.data.min_delivery_time;
                this.max_delivery_time= response.data.max_delivery_time;
                this.pickup_window=response.data.pickup_window;
            }
            catch (e) {
                this.loading = false;
                console.log('error is',e)
                var i;
                for (i = 0; i < e.response.data.errors.length; ++i) {
                    this.$toasted.error(e.response.data.errors[i].message)
                }
                if(e.response.status== 450){ // logout the user
                    await this.$store.dispatch("logout");
                    await this.$router.push('/')
                }else if (e.response.status== 401){
                    await this.$RefreshToken()
                } 
            }

            // hitting api to get time slots data
            try{
                this.loading= true;
                const headers = { 
                    "Content-Type": "application/json",
                    "Authorization":'Bearer '+this.$store.state.access_token
                };
                api_url = `${baseUrl}/api/brands/`+brand_id+`/time-blocks/`
                const response =  await axios.get(api_url,{headers})
                this.loading= false;
                var result=response.data.results
                console.log('=========time slots data==========',result);
                for (i = 0; i < result.length; ++i) {
                    if(result[i] && result[i].order_type){
                        // dine in
                        if(result[i].order_type.includes("dine_in")){
                            result[i].dine_in=true
                        }else{
                            result[i].dine_in=false
                        }
                        // take_away
                        if(result[i].order_type.includes("take_away")){
                            result[i].take_away=true
                        }else{
                            result[i].take_away=false
                        } 
                        // delivery
                        if(result[i].order_type.includes("delivery")){
                            result[i].delivery=true
                        }else{
                            result[i].delivery=false
                        }
                    }
                    if(result[i] && result[i].days_of_week){
                        // monday
                        if(result[i].days_of_week.includes(0)){
                            result[i].monday=true
                        }else{
                            result[i].monday=false
                        }

                        if(result[i].days_of_week.includes(1)){
                            result[i].tuesday=true
                        }else{
                            result[i].tuesday=false
                        }    
                        if(result[i].days_of_week.includes(2)){
                            result[i].wednesday=true
                        }else{
                            result[i].wednesday=false
                        }    
                        if(result[i].days_of_week.includes(3)){
                            result[i].thursday=true
                        }else{
                            result[i].thursday=false
                        }    
                        if(result[i].days_of_week.includes(4)){
                            result[i].friday=true
                        }else{
                            result[i].friday=false
                        }    
                        if(result[i].days_of_week.includes(5)){
                            result[i].saturday=true
                        }else{
                            result[i].saturday=false
                        }    
                        if(result[i].days_of_week.includes(6)){
                            result[i].sunday=true
                        }else{
                            result[i].sunday=false
                        }

                    }
                }

                this.timeblock_results=result

            }
            catch (e) {
                this.loading= false;
                console.log('error is',e)
                for (i = 0; i < e.response.data.errors.length; ++i) {
                    this.$toasted.error(e.response.data.errors[i].message)
                }
                if(e.response.status== 450){ // logout the user
                    await this.$store.dispatch("logout");
                    await this.$router.push('/')
                }else if (e.response.status== 401){
                    await this.$RefreshToken()
                } 
            }

        }
    }

</script>


<style>

</style>